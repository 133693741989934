(function() {
  var ready;

  ready = function() {
    var $cidr_text_area, $make_public;
    $make_public = $('[id$="app_public"]');
    $cidr_text_area = $('[data-cidr-blocks="true"]');
    if ($cidr_text_area[0]) {
      if ($make_public[0].checked) {
        $cidr_text_area.hide();
      } else {
        $cidr_text_area.show();
      }
      return $make_public.click(function() {
        if (this.checked) {
          return $cidr_text_area.hide();
        } else {
          return $cidr_text_area.show();
        }
      });
    }
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
